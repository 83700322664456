import { createStore } from 'effector';
import { rbp_business_types } from '../../dictionaries/rbp_business_types.js';

let initialState = [
  {
    title: 1,
    key: 'business_1',
    checkable: false,
    children: [
      {
        title: 101,
        key: 'business_101',
      },
      {
        title: 102,
        key: 'business_102',
      },
      {
        title: 103,
        key: 'business_103',
      },
      {
        title: 104,
        key: 'business_104',
      },
      {
        title: 105,
        key: 'business_105',
      },
      {
        title: 106,
        key: 'business_106',
      },
      {
        title: 107,
        key: 'business_107',
      },
    ],
  },
  {
    title: 2,
    key: 'business_2',
    checkable: false,
    children: [
      {
        title: 201,
        key: 'business_201',
      },
    ],
  },
  {
    title: 3,
    key: 'business_3',
    checkable: false,
    children: [
      {
        title: 301,
        key: 'business_301',
      },
      {
        title: 302,
        key: 'business_302',
      },
      {
        title: 303,
        key: 'business_303',
      },
      {
        title: 304,
        key: 'business_304',
      },
      {
        title: 305,
        key: 'business_305',
      },
      {
        title: 306,
        key: 'business_306',
      },
      {
        title: 307,
        key: 'business_307',
      },
      {
        title: 308,
        key: 'business_308',
      },
      {
        title: 309,
        key: 'business_309',
      },
    ],
  },
  {
    title: 4,
    key: 'business_4',
    checkable: false,
    children: [
      {
        title: 401,
        key: 'business_401',
      },
      {
        title: 402,
        key: 'business_402',
      },
      {
        title: 403,
        key: 'business_403',
      },
    ],
  },
  {
    title: 5,
    key: 'business_5',
    checkable: false,
    children: [
      {
        title: 501,
        key: 'business_501',
      },
      {
        title: 502,
        key: 'business_502',
      },
    ],
  },
];

initialState = initialState.map((item) => {
  return {
    ...item,
    title: rbp_business_types[item.title].ru,
    children: item.children.map((child) => {
      return {
        ...child,
        title: rbp_business_types[child.title].ru,
      };
    }),
  };
});

export const $businessTypeTree = createStore(initialState);
$businessTypeTree.watch((state) => console.log('$businessTypeTree', state));
