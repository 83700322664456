import { createEffect } from 'effector';
import { api } from '../../api/axiosinstance.js';

export const loginFx = createEffect().use(async (payload) => {
  return (await api(false).post('users/login', payload)).data;
});

export const registerFx = createEffect().use(async (payload) => {
  return (await api(false).post('users/signup', payload)).data;
});

export const logoutFx = createEffect().use(async () => {
  return (await api(true).post('users/logout')).data;
});

export const getUserListFx = createEffect().use(async () => {
  return (await api(true).get('users/')).data;
});

export const confirmUserFx = createEffect().use(async (id) => {
  return {
    data: (await api(true).post(`users/${id}/confirm`)).data,
    id,
    field: 'is_confirmed',
    value: true,
  };
});

export const blockUserFx = createEffect().use(async (id) => {
  return {
    data: (await api(true).post(`users/${id}/block`)).data,
    id,
    field: 'is_blocked',
    value: true,
  };
});

export const unblockUserFx = createEffect().use(async (id) => {
  return {
    data: (await api(true).post(`users/${id}/unblock`)).data,
    id,
    field: 'is_blocked',
    value: false,
  };
});

export const makeAdminFx = createEffect().use(async (id) => {
  return {
    data: (await api(true).post(`users/${id}/admin`)).data,
    id,
    field: 'perms',
    value: ['admin'],
  };
});

export const sendTimersFx = createEffect().use(async ({ id, timers }) => {
  return (
    await api(true).post(`users/${id}/timers`, null, { params: { ...timers } })
  ).data;
});

export const switchUseBackendFx = createEffect().use(
  async ({ id, useBackend }) => {
    return {
      data: (await api(true).post(`users/${id}/use_backend/${useBackend}`))
        .data,
      id,
      field: 'use_backend',
      value: useBackend,
    };
  }
);

export const delayShowLeftBarFx = createEffect().use(async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve(false);
    }, 1000);
  });
});
