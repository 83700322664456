import { sample } from 'effector';
import {
  $drawMode,
  $hexagonsHeight,
  $interactivity,
  $layersOpacity,
  $mapLoaded,
  $searchOptions,
} from './stores.js';
import {
  changeDrawModeEv,
  changeHexagonsHeightEv,
  changeInteractivityEv,
  changeLayersOpacityEv,
  putSearchOptionsEv,
  resetMapLoadedEv,
  setMapLoadedEv,
} from './events.js';
import { $presetAreaToEdit } from '../dataPresetsModel/index.js';
import {
  $invertHeight,
  changeInvertHeightEv,
} from '../../../models/mapModel/index.js';

$mapLoaded.on(setMapLoadedEv, (_, payload) => payload).reset(resetMapLoadedEv);

$interactivity.on(changeInteractivityEv, (_, payload) => payload);

$searchOptions.on(putSearchOptionsEv, (_, payload) => payload);

$drawMode.on(changeDrawModeEv, (_, payload) => payload);

$layersOpacity.on(changeLayersOpacityEv, (_, payload) => payload);

$hexagonsHeight.on(changeHexagonsHeightEv, (_, payload) => payload);

sample({
  clock: $presetAreaToEdit.updates,
  fn: (clock) => !clock.preset,
  target: changeInteractivityEv,
});

sample({
  source: $invertHeight,
  clock: changeInvertHeightEv,
  fn: (source, clock) => {
    if (typeof clock === 'boolean') {
      return clock;
    }
    return !source;
  },
  target: $invertHeight,
});
