import {
  putFilteredZoom8HexagonsEv,
  putZoom8HexagonsEv,
} from '../models/zoom8Model/index.js';
import {
  putFilteredZoom9HexagonsEv,
  putZoom9HexagonsEv,
} from '../models/zoom9Model/index.js';
import {
  putFilteredZoom10HexagonsEv,
  putZoom10HexagonsEv,
} from '../models/zoom10Model/index.js';
import { hideLoaderEv, showLoaderEv } from '../models/webSocketModel/index.js';
import {
  putChartDataEv,
  putCityIndexEv,
  putFilteredChartDataEv,
  putFilteredIndexCardsDataEv,
  putIndexCardsDataEv,
} from '../models/indexCardsModel/index.js';
import { setUserInfoEv, updateFavoritesEv } from '../models/authModel/index.js';
import { cookies } from '../api/axiosinstance.js';
import { putSearchOptionsEv } from '../models/mapModel/index.js';
import { putIsochroneDataEv } from '../models/isochroneModel/index.js';
import { putBlockedBucketsEv } from '../models/gradientModel/index.js';
import { getDataPresetsFx } from '../models/dataPresetsModel/index.js';
import { putEconomicsDataEv } from '../models/economicsModel/index.js';
import { putSocdemDataEv } from '../models/socdemModel/index.js';

const favFiledDict = {
  rcp: 'favorites',
  rbp: 'rbp_favorites',
  'rbp/chart': 'rbp_chart_favorites',
};

export function wsOnMessageHandler(message) {
  message = JSON.parse(message.data);
  console.log('WEBSOCKET:: wsOnMessageHandler: message', message);

  switch (message.action) {
    case 'users/login': {
      if (message.response.includes('expired token')) {
        cookies.remove('access_token', { path: '/' });
        window.location.replace('/login');
      }
      break;
    }
    case 'rcp/get_polygons':
      switch (message.part) {
        case 'zoom_8':
          // putZoom8HexagonsEv(message.response.polygons);
          // putBlockedBucketsEv({
          //   zoom: 'zoom_8',
          //   value: message.response.gradient,
          // });
          break;
        case 'zoom_9':
          // putZoom9HexagonsEv(message.response.polygons);
          // putBlockedBucketsEv({
          //   zoom: 'zoom_9',
          //   value: message.response.gradient,
          // });
          break;
        case 'zoom_10':
          window.download_end = performance.now();
          window.mutate_start = performance.now();
          putZoom10HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_10',
            value: message.response.gradient,
          });
          getDataPresetsFx();
          // hideLoaderEv();
          break;
        case 'city_total':
          putIndexCardsDataEv(message.response);
          putChartDataEv(message.response);
          putCityIndexEv(message.response.index);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'rbp/get_polygons':
      switch (message.part) {
        case 'city_data':
          putIndexCardsDataEv(message.response);
          putChartDataEv(message.response);
          putCityIndexEv(message.response.index);
          break;
        case 'zoom_data':
          window.download_end = performance.now();
          window.mutate_start = performance.now();
          putZoom9HexagonsEv(message.response.polygons);
          break;
        case 'diagram_data':
          putSocdemDataEv(message.response[1]);
          putEconomicsDataEv(message.response[2]);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'rcp/get_polygons_filtered':
      switch (message.part) {
        case 'zoom_8':
          putFilteredZoom8HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_8',
            value: message.response.gradient,
          });
          break;
        case 'zoom_9':
          putFilteredZoom9HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_9',
            value: message.response.gradient,
          });
          break;
        case 'zoom_10':
          putFilteredZoom10HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_10',
            value: message.response.gradient,
          });
          hideLoaderEv();
          break;
        case 'city_total':
          putFilteredIndexCardsDataEv(message.response);
          putFilteredChartDataEv(message.response);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'users/info':
      setUserInfoEv(message.response);
      break;
    case 'map/get_point':
      putSearchOptionsEv(message.response);
      break;
    case 'map/get_isochrone':
      const id = `isochrone_lat_${message.payload.lat}_lon_${message.payload.lon}`;
      putIsochroneDataEv({ response: message.response, id });
      break;
    case 'user/favorite/add':
    case 'user/favorite/remove':
      {
        const field = favFiledDict[message.payload.api];
        updateFavoritesEv({
          field,
          value: message.response,
        });
      }
      break;
    default:
      break;
  }
}

export function wsGetAlmatyPolygons() {
  showLoaderEv();
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'rcp/get_polygons',
      partial: true,
    })
  );
}

export function wsSendAddFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        api: 'rcp',
        index_ids: indexes,
      },
    })
  );
}
export function wsSendRemoveFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        api: 'rcp',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendDarkTheme(dark_theme) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/set_dark_theme',
      payload: {
        enable: dark_theme,
      },
    })
  );
}

export function wsGetSearchOptions(place) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'map/get_point',
      payload: {
        address: place,
      },
      partial: false,
    })
  );
}

export function wsGetIsochrone(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'map/get_isochrone',
      payload: {
        lat: payload.coordinates ? payload.coordinates[1] : null,
        lon: payload.coordinates ? payload.coordinates[0] : null,
        time: payload.time ? payload.time : 5,
        traffic: payload.traffic
          ? payload.type === 'walk'
            ? false
            : payload.traffic
          : false,
        type: payload.type ? payload.type : 'walk',
      },
    })
  );
}

export function wsGetFilteredPolygons(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rcp/get_polygons_filtered',
      payload: {
        region_ids: payload.region_ids ? payload.region_ids : [],
        index_ids: payload.index_ids ? payload.index_ids : [],
      },
      partial: true,
    })
  );
}

export function wsGetRBPData(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'rbp/get_polygons',
      payload: {
        business: payload?.business ? payload.business : '101',
        index_ids: payload?.index_ids ? payload.index_ids : [],
        zoom_ids: payload?.zoom_ids ? payload.zoom_ids : [],
      },
      partial: true,
    })
  );
}

export function wsSendRBPAddFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        api: 'rbp',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendRBPRemoveFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        api: 'rbp',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendRBPAddFavoritesChart(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        api: 'rbp/chart',
        index_ids: indexes,
      },
    })
  );
}

export function wsSendRBPRemoveFavoritesChart(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        api: 'rbp/chart',
        index_ids: indexes,
      },
    })
  );
}
