export const rbp_business_types = {
  1: {
    ru: 'Красота / Здоровье',
    en: 'Beauty / Health',
  },
  2: {
    ru: 'Медицинские товары',
    en: 'Medical products',
  },
  3: {
    ru: 'Общественное питание',
    en: 'Catering',
  },
  4: {
    ru: 'Продуктовый ритейл',
    en: 'Grocery retail',
  },
  5: {
    ru: 'Спортивные клубы',
    en: 'Sports clubs',
  },
  101: {
    ru: 'Барбершопы',
    en: 'Barbershops',
  },
  102: {
    ru: 'Детские парикмахерские',
    en: "Children's hairdressers",
  },
  103: {
    ru: 'Ногтевые студии',
    en: 'Nail studios',
  },
  104: {
    ru: 'Парикмахерские',
    en: 'Hairdressers',
  },
  105: {
    ru: 'Услуги по оформлению ресниц / бровей',
    en: 'Eyelash/eyebrow styling services',
  },
  106: {
    ru: 'Эпиляция',
    en: 'Epilation',
  },
  107: {
    ru: 'SPA-процедуры',
    en: 'SPA treatments',
  },
  201: {
    ru: 'Аптеки',
    en: 'Pharmacies',
  },
  301: {
    ru: 'Бары',
    en: 'Bars',
  },
  302: {
    ru: 'Быстрое питание',
    en: 'Fast food',
  },
  303: {
    ru: 'Кафе',
    en: 'Cafe',
  },
  304: {
    ru: 'Кафе-кондитерские',
    en: 'Cafes',
  },
  305: {
    ru: 'Кофейни',
    en: 'Coffee shops',
  },
  306: {
    ru: 'Кофейные автоматы',
    en: 'Coffee machines',
  },
  307: {
    ru: 'Пекарни',
    en: 'Bakeries',
  },
  308: {
    ru: 'Пиццерии',
    en: 'Pizzerias',
  },
  309: {
    ru: 'Рестораны',
    en: 'Restaurants',
  },
  310: {
    ru: 'Столовые',
    en: 'Dining rooms',
  },
  311: {
    ru: 'Суши-бары',
    en: 'Sushi bars',
  },
  312: {
    ru: 'Точки кофе',
    en: 'Coffee points',
  },
  313: {
    ru: 'Фудкорты',
    en: 'Food courts',
  },
  401: {
    ru: 'Продуктовые магазины',
    en: 'Grocery stores',
  },
  402: {
    ru: 'Гипермаркеты',
    en: 'Hypermarkets',
  },
  403: {
    ru: 'Супермаркеты',
    en: 'Supermarkets',
  },
  501: {
    ru: 'Фитнес-клубы',
    en: 'Fitness clubs',
  },
  502: {
    ru: 'Центры йоги',
    en: 'Yoga centers',
  },
};
