import { createStore } from 'effector';

export const $authStatus = createStore(false);
$authStatus.watch((state) => console.log('$authStatus', state));

export const $userInfo = createStore({});
$userInfo.watch((state) => console.log('$userInfo', state));

export const $isDarkTheme = createStore(false);
$isDarkTheme.watch((state) => console.log('$isDarkTheme', state));

export const $language = createStore('ru');
$language.watch((state) => console.log('$language', state));

export const $userList = createStore([]);
$userList.watch((state) => console.log('$userList', state));

export const $isTablet = createStore(window.innerWidth <= 1200);

export const $tabletBarHidden = createStore(true);

export const $leftBarSwitched = createStore(false);
$leftBarSwitched.watch((state) => console.log('$leftBarSwitched', state));
