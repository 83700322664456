import { Button, Switch, Table, Tooltip } from 'antd';
import { useUnit } from 'effector-react';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EyeOutlined,
  MinusCircleOutlined,
  PlusSquareOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from './UserList.module.css';
import {
  $isDarkTheme,
  $language,
  $userInfo,
  $userList,
  blockUserFx,
  confirmUserFx,
  getUserListFx,
  makeAdminFx,
  switchUseBackendFx,
  unblockUserFx,
} from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';

export default function UserList() {
  const loading = useUnit(getUserListFx.pending);
  const language = useUnit($language);
  const userList = useUnit($userList);
  // const userInfo = useUnit($userInfo);
  const confirmUser = useUnit(confirmUserFx);
  const confirmUserLoading = useUnit(confirmUserFx.pending);
  const blockUser = useUnit(blockUserFx);
  const blockUserLoading = useUnit(blockUserFx.pending);
  const unblockUser = useUnit(unblockUserFx);
  const unblockUserLoading = useUnit(unblockUserFx.pending);
  const makeAdmin = useUnit(makeAdminFx);
  const makeAdminLoading = useUnit(makeAdminFx.pending);
  const switchUseBackend = useUnit(switchUseBackendFx);
  const switchUseBackendLoading = useUnit(switchUseBackendFx.pending);
  const navigate = useNavigate();
  const darkMode = useUnit($isDarkTheme);

  const columns = [
    {
      title: ru_en_page_dictionary.table_name[language],
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: ru_en_page_dictionary.table_email[language],
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: ru_en_page_dictionary.table_organization[language],
      dataIndex: 'organization',
      key: 'organization',
    },
    // {
    //   title: ru_en_page_dictionary.table_performance[language],
    //   dataIndex: '',
    //   key: 'performance',
    //   render: (text, record) => {
    //     return (
    //       <>
    //         <div>
    //           {ru_en_page_dictionary.table_download[language]}:{record.download}
    //         </div>
    //         <br />
    //         <div>
    //           {ru_en_page_dictionary.table_mutate[language]}: {record.mutate}
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      title: ru_en_page_dictionary.table_admin[language],
      dataIndex: '',
      key: 'is_admin',
      render: (text, record) => {
        if (record.perms.includes('admin')) {
          return <CheckCircleOutlined style={{ color: 'green' }} />;
        }
        return (
          <>
            <MinusCircleOutlined style={{ color: 'red', marginRight: '5px' }} />
            {makeAdminLoading ? (
              <SyncOutlined spin />
            ) : (
              <Tooltip title={ru_en_page_dictionary.make_admin[language]}>
                <PlusSquareOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => makeAdmin(record.id)}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: ru_en_page_dictionary.table_timers[language],
      dataIndex: 'timers',
      key: 'timers',
    },
    {
      title: ru_en_page_dictionary.table_status[language],
      dataIndex: '',
      key: 'status',
      render: (text, record) => {
        if (record.is_blocked) {
          return (
            <Tooltip title={ru_en_page_dictionary.status_blocked[language]}>
              <CloseCircleOutlined style={{ color: 'red' }} />
            </Tooltip>
          );
        }
        if (record.is_confirmed) {
          return (
            <Tooltip title={ru_en_page_dictionary.status_confirmed[language]}>
              <CheckCircleOutlined style={{ color: 'green' }} />
            </Tooltip>
          );
        }
        return (
          <Tooltip title={ru_en_page_dictionary.status_undefined[language]}>
            <QuestionCircleOutlined style={{ color: 'grey' }} />
          </Tooltip>
        );
      },
    },
    {
      title: ru_en_page_dictionary.table_action[language],
      dataIndex: '',
      key: 'action',
      render: (text, record) => {
        return (
          <div className={style.actions_wrapper}>
            {record.is_blocked ? (
              <Tooltip
                title={ru_en_page_dictionary.table_action_unblock[language]}
              >
                <Button
                  className={style.action_button}
                  icon={<UndoOutlined />}
                  onClick={() => unblockUser(record.id)}
                  loading={unblockUserLoading}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={ru_en_page_dictionary.table_action_block[language]}
              >
                <Button
                  className={style.action_button}
                  icon={<CloseOutlined />}
                  onClick={() => blockUser(record.id)}
                  loading={blockUserLoading}
                />
              </Tooltip>
            )}
            {!record.is_confirmed ? (
              <Tooltip
                title={ru_en_page_dictionary.table_action_confirm[language]}
              >
                <Button
                  className={style.action_button}
                  icon={<CheckOutlined />}
                  onClick={() => confirmUser(record.id)}
                  loading={confirmUserLoading}
                />
              </Tooltip>
            ) : (
              ''
            )}
            <Tooltip
              title={`${ru_en_page_dictionary.table_switch[language]} ${
                record.use_backend
                  ? ru_en_page_dictionary.table_to_front[language]
                  : ru_en_page_dictionary.table_to_back[language]
              }`}
            >
              <Switch
                style={{ marginRight: '10px' }}
                checked={record.use_backend}
                onChange={(checked) =>
                  switchUseBackend({
                    id: record.id,
                    useBackend: checked,
                  })
                }
                loading={switchUseBackendLoading}
              />
            </Tooltip>
            <Tooltip title={ru_en_page_dictionary.table_action_view[language]}>
              <Link to={`/user/${record.id}`} target="_blank">
                <Button
                  className={style.action_button}
                  icon={<EyeOutlined />}
                />
              </Link>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // useEffect(() => {
  //   if (Object.keys(userInfo).length > 0) {
  //     if (!userInfo.perms.includes('admin')) {
  //       navigate('/root');
  //     }
  //   }
  // }, [userInfo]);

  return (
    <div className={style.container}>
      <div
        className={style.user_list_tilte}
        style={{ color: darkMode ? 'white' : 'black' }}
      >
        {ru_en_page_dictionary.user_list_title[language]}
      </div>
      <Table
        className={style.user_list_table}
        loading={loading}
        columns={columns}
        dataSource={userList}
        scroll={{
          y: 800,
        }}
        pagination={false}
      />
    </div>
  );
}
