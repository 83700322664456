import { Button, Checkbox, Form, Input } from 'antd';
import { useUnit } from 'effector-react';
import style from './ReportModal.module.css';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { all_metrics } from '../../dictionaries/metrics.js';
import { $language } from '../../models/authModel/index.js';
import { sendGenerateReportFx } from '../../models/reportModel/index.js';
import { $activeFilters } from '../../models/activeFiltersModel/index.js';
import { $zoom10Hexagons } from '../../models/zoom10Model/index.js';

export default function ReportForm() {
  const language = useUnit($language);
  const sendGenerateReport = useUnit(sendGenerateReportFx);
  const activeFilters = useUnit($activeFilters);
  const zoom10Hexagons = useUnit($zoom10Hexagons);

  const onGenerateReport = (values) => {
    const payload = {
      filename: values.filename,
      index_ids: all_metrics
        .filter((item) => !activeFilters.excludedIndexes.includes(item))
        .map((item) => parseInt(item.replace('index_', ''))),
      region_ids: activeFilters.district,
      polygon_ids: zoom10Hexagons.map((item) => item.properties.hex_id),
      use_index: !!values.use_index,
      use_zones: !!values.use_zones,
      split_by_region: !!values.split_by_region,
      absolute_properties: !!values.absolute_properties,
    };

    sendGenerateReport(payload);
  };
  return (
    <Form onFinish={onGenerateReport} className={style.form_wrapper}>
      <Form.Item
        style={{ marginBottom: '10px' }}
        name="filename"
        required
        rules={[
          {
            validator: (_, value) => {
              if (value && !/\/|,|:|\*|\?|"|<|>\||~|\\|;/g.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(
                ru_en_page_dictionary.report_name_error[language]
              );
            },
          },
        ]}
      >
        <div className={style.input_wrapper}>
          <div className={style.input_label}>
            {ru_en_page_dictionary.report_name[language]}
          </div>
          <Input placeholder={ru_en_page_dictionary.report_name[language]} />
        </div>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '10px' }}
        name="use_index"
        valuePropName="checked"
      >
        <Checkbox className={style.report_checkbox}>
          {ru_en_page_dictionary.report_checkbox_1[language]}
        </Checkbox>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '10px' }}
        name="use_zones"
        valuePropName="checked"
      >
        <Checkbox className={style.report_checkbox}>
          {ru_en_page_dictionary.report_checkbox_2[language]}
        </Checkbox>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '10px' }}
        name="split_by_region"
        valuePropName="checked"
      >
        <Checkbox className={style.report_checkbox}>
          {ru_en_page_dictionary.report_checkbox_3[language]}
        </Checkbox>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '10px' }}
        name="absolute_properties"
        valuePropName="checked"
      >
        <Checkbox className={style.report_checkbox}>
          {ru_en_page_dictionary.report_checkbox_4[language]}
        </Checkbox>
      </Form.Item>
      <Form.Item style={{ marginBottom: 'unset' }}>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          {ru_en_page_dictionary.report_button[language]}
        </Button>
      </Form.Item>
    </Form>
  );
}
