import { createStore } from 'effector';

const initialStore = {
  draw_polygon: [],
  quarter: 11,
  district: [],
  zoom8_hex: {
    id: '',
    centerCoords: [],
  },
  zoom9_hex: {
    id: '',
    centerCoords: [],
  },
  zoom10_hex: {
    id: '',
    centerCoords: [],
  },
  excludedIndexes: [],
  gradient: [],
  chosen_metrics: [],
  business_type: 101,
};

export const $activeFilters = createStore(initialStore);
$activeFilters.watch((state) => console.log('$activeFilters', state));
