import { createEffect } from 'effector';
import { wsGetIsochrone } from '../../utils/webSocketConfig.js';

export const getIsochroneFx = createEffect().use(
  ({ coordinates, time, traffic, type }) => {
    coordinates.forEach((point) => {
      wsGetIsochrone({
        coordinates: point.geometry.coordinates,
        time,
        traffic,
        type,
      });
    });
    // wsGetIsochrone({ coordinates, time, traffic, type });
  }
);
